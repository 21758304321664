<template>
  <div>
    <div class="title">{{ $t("createArOrder") }}</div>
    <el-form :rules="rules" ref="form" :model="form" label-width="200px">
      <el-form-item :label="$t('companyOrderAgain')" prop="num">
        <el-input v-model="form.num"></el-input>
      </el-form-item>
      <el-form-item :label="$t('payerAgain')" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Orderamount')" prop="money">
        <el-input
          v-model="form.money"
          maxlength="5"
          oninput="value=value.replace(/[^0-9]/g,'')"
        ></el-input>
        <span>&nbsp;{{ $t("millionDollars") }}</span>
      </el-form-item>
      <el-form-item :label="$t('contractStatusAgain')" prop="type">
        <el-select
          v-model="form.type"
          :placeholder="$t('selectContractStatus')"
        >
          <el-option :label="$t('signed')" value="1"></el-option>
          <el-option :label="$t('notSigned')" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('orderStatus')" prop="method">
        <el-select
          v-model="form.method"
          :placeholder="$t('selectProductionStatus')"
        >
          <el-option :label="$t('distribution')" value="1"></el-option>
          <el-option :label="$t('production')" value="2"></el-option>
          <el-option :label="$t('distributionGoods')" value="3"></el-option>
          <el-option :label="$t('deliver')" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('dateOfIssuance')" required>
        <el-form-item prop="endDate">
          <el-date-picker
            type="date"
            :placeholder="$t('selectDeliveryDate')"
            v-model="form.endDate"
          ></el-date-picker>
        </el-form-item>
      </el-form-item>
      <el-form-item :label="$t('promisedPaymentDateAgain')" required>
        <el-form-item prop="payDate">
          <el-date-picker
            type="date"
            :placeholder="$t('selectPaymentDate')"
            v-model="form.payDate"
          ></el-date-picker>
        </el-form-item>
      </el-form-item>
      <el-form-item :label="$t('uploadOrderContract')" required>
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="3"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">{{
            $t("clickUpload")
          }}</el-button>
          <div slot="tip" class="el-upload__tip">{{ $t("uploadRules") }}</div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div class="flex-column-center">
      <el-button class="submit btn-black" @click="submitForm('form')">{{
        $t("submit")
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        num: [{ required: true, message: this.$t('numberCannotEmpty'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('payerCannotEmpty'), trigger: 'blur' }],
        money: [{ required: true, message: this.$t('amountCannotEmpty') }],
        type: [{ required: true, message: this.$t('selectContractStatus'), trigger: 'change' }],
        method: [{ required: true, message: this.$t('selectProductionStatus'), trigger: 'change' }],
        day: [{ required: true, message: this.$t('periodCannotEmpty') }, { type: 'number', message: this.$t('periodMustNumber') }],
        endDate: [{ type: 'date', required: true, message: this.$t('SelectDeliveryDate'), trigger: 'change' }],
        payDate: [{ type: 'date', required: true, message: this.$t('selectPromisedPaymentDate'), trigger: 'change' }],
      },
      fileList: []
    };
  },
  methods: {
    submitForm(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.$message({
            type: 'success',
            message: this.$t('submittedSuccessfully')
          });
          setTimeout(() => {
            this.$router.push({ path: '/receivable-list' });
          }, 1000);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      this.$message({
        message: this.$t('limitedSelected') + this.$t('iChose') + `${files.length}` + this.$t('filesNumber') + this.$t('selected') + `${files.length + fileList.length}` + this.$t('filesNumber'),
        type: 'warning'
      });
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`${this.$t('sureRemove')} ${file.name}？`);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-input__inner {
  width: 330px;
}
</style>
